@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,500;1,700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

body{
    background: #FFF;
    font: 400 16px Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
}

input, button { 
    font: 400 18px Roboto, sans-serif;
}

ul {
    list-style: none;
}

button{
    cursor: pointer;
}
.card-page-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-page-container p, .card-page-container h5{
  color: rgb(30, 30, 30);
  max-width: 400px;
  text-align: center;
  font-size: 15px;
}

.card-page-container strong{
  font-size: 14px;
}
.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: 200px;
  justify-content: space-between;
  background: rgb(35, 35, 35);
  border-radius: 12px;
  padding: 25px;
  margin: 20px 10px;
  box-shadow: 10px 10px 16px -2px rgba(0, 0, 0, 0.6);
}

.card-container img {
  max-width: 100px;
  align-self: flex-end;
}

.informations {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: rgb(255, 255, 255);
}

.informations strong{
  margin-top: 5px;
  font-size: 15px;
}
