.card-page-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-page-container p, .card-page-container h5{
  color: rgb(30, 30, 30);
  max-width: 400px;
  text-align: center;
  font-size: 15px;
}

.card-page-container strong{
  font-size: 14px;
}