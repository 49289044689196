.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: 200px;
  justify-content: space-between;
  background: rgb(35, 35, 35);
  border-radius: 12px;
  padding: 25px;
  margin: 20px 10px;
  -webkit-box-shadow: 10px 10px 16px -2px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 10px 10px 16px -2px rgba(0, 0, 0, 0.6);
  box-shadow: 10px 10px 16px -2px rgba(0, 0, 0, 0.6);
}

.card-container img {
  max-width: 100px;
  align-self: flex-end;
}

.informations {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: rgb(255, 255, 255);
}

.informations strong{
  margin-top: 5px;
  font-size: 15px;
}