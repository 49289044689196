@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,500;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

body{
    background: #FFF;
    font: 400 16px Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
}

input, button { 
    font: 400 18px Roboto, sans-serif;
}

ul {
    list-style: none;
}

button{
    cursor: pointer;
}